import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'calendar-availability';

  background = 'url(./assets/ZFC_LOGO.png)';
  listBackground = ['1', '2', '3', '4', '5', '6', '1', '2', '3', '4', '5', '6'];
  currentIndex = '1';
  imgs = new Array();
  
  protected headerClass: string = null;

  constructor() {
    // setInterval(() => {
    //   let index: string = this.listBackground[Math.floor(Math.random() * this.listBackground.length)];
    //   if (this.currentIndex === index) {
    //     index = this.listBackground[Math.floor(Math.random() * this.listBackground.length)];
    //     this.currentIndex = index;
    //   }
    //   this.background = 'url(./assets/bg' + index + '.jpg)';
    // }, 15000);
  }

  ngOnInit() {
    // this.listBackground.forEach((indexBg, index) => {
    //   const background = './assets/bg' + indexBg + '.jpg';
    //   this.imgs[index] = new Image();
    //   this.imgs[index].src = background;
    // });
  }

  @HostListener("window:scroll", ['$event'])
  onWindowScroll(e) {
    this.headerClass = window.pageYOffset > 100 ? 'navbar-shrink' : '';
  }
}
