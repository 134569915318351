import { Component, Input } from '@angular/core';

@Component({
    selector: 'header-app',
    templateUrl: './header.component.html'
})
export class HeaderComponent {

    @Input() headerClass: string;

    constructor() { }
}
