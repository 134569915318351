import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {Location} from '@angular/common';


const DISCORD_API_OAUTH_URL = 'https://discordapp.com/api/oauth2/';
const DISCORD_API_URL = 'https://discordapp.com/api/v6/';
const API_URL = 'http://calendar.yougo.me/api/crud/';
// const API_URL = 'http://localhost/calendar-availability/api/crud/';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private CLIENT_ID = '618456012482936832';
  private CLIENT_SECRET = 'YXa7k2WuiOv3eQrEFV7dLdp53iYFuu0V';
  private redirect = encodeURIComponent('http://calendar.yougo.me/');

  private httpHeaders = new HttpHeaders({
      'Content-Type' : 'text/plain; charset=UTF-8'
  });

  constructor(private httpClient: HttpClient) {}

  public getAvailabilities() {
    return this.httpClient.post(`${API_URL}read.php`, null, {
      headers: this.httpHeaders
    });
  }

  public loginToDiscord() {
    // tslint:disable-next-line: max-line-length
    window.location.href = `${DISCORD_API_OAUTH_URL}authorize?client_id=${this.CLIENT_ID}&scope=identify&response_type=code&redirect_uri=${this.redirect}`;
  }

  public postCredential(code: string) {
    const creds = window.btoa(`${this.CLIENT_ID}:${this.CLIENT_SECRET}`);
    // tslint:disable-next-line: max-line-length
    return this.httpClient.post(`${DISCORD_API_OAUTH_URL}token?grant_type=authorization_code&code=${code}&redirect_uri=${this.redirect}`,
      {
        scope: 'identify connections'
      },
      {
        headers: {
          Authorization: `Basic ${creds}`,
        },
      }
    );
  }

  public getInfoDiscord(token?) {
    if (!token) {
      token = JSON.parse(localStorage.getItem('token'));
    }
    return this.httpClient.get(`${DISCORD_API_URL}users/@me`,
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    });
  }

  public addAvailability(time, userId, status, username) {
    return this.httpClient.post(`${API_URL}add.php`,
    {
      timestamp: time,
      userId,
      username,
      status
    }, {
      headers: this.httpHeaders
    });
  }

  public removeAvailability(time, userId) {
    return this.httpClient.post(`${API_URL}remove.php`,
    {
      timestamp: time,
      userId
    }, {
      headers: this.httpHeaders
    });
  }
}
