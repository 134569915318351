import { Component, OnInit, Pipe, PipeTransform, ChangeDetectorRef} from '@angular/core';
import Day from '../model/day.model';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html'
})
export class CalendarComponent implements OnInit {

  protected listOfDays: Day[] = null;
  protected dateToday: Date = null;
  protected loading = true;

  protected avatar = null;
  protected currentUser = null;

  protected enumDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  protected numberRow = new Array(6);

  protected showErrorMessage = false;
  private timeout = null;

  
  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router, private cdRef: ChangeDetectorRef) {

    const code = this.route.snapshot.queryParams.code;
    if (code) {
      this.apiService.postCredential(code).subscribe((res) => {

        const token = res;
        localStorage.setItem('token', JSON.stringify(token));

        this.router.navigate([''], {});
        this.apiService.getInfoDiscord(token).subscribe((res: any) => {
          this.currentUser = res;
          this.avatar = 'https://cdn.discordapp.com/avatars/' + res.id + '/' + res.avatar + '.png';
          this.loading = false;
        });
      }, (err) => {
        this.clearSession();
      });
    } else if (localStorage.getItem('token')) {
      this.apiService.getInfoDiscord().subscribe((res: any) => {
        this.currentUser = res;
        this.avatar = 'https://cdn.discordapp.com/avatars/' + res.id + '/' + res.avatar + '.png';
        this.loading = false;
      }, (err) => {
        this.clearSession();
      });
    } else {
      this.loading = false;
    }
    this.dateToday = new Date();
  }

  ngOnInit() {
    this.refresh();
  }

  private refresh(): void {
    const nbDays = new Date(this.dateToday.getFullYear(), this.dateToday.getMonth() + 1, 0).getDate();

    this.apiService.getAvailabilities().subscribe((availabilities: any[]) => {

      this.listOfDays = [];
      const listTmpOfDay = [];

      for (let i = this.dateToday.getDate() ; i <= nbDays ; i++) {
        const tmpDate = new Date(this.dateToday.getFullYear(), this.dateToday.getMonth(), i);
        const listUsers = availabilities.filter(a => a.timestamp === tmpDate.getTime().toString());
        listTmpOfDay.push({
          date: tmpDate,
          availables: listUsers.filter(it => it.status === '1'),
          perhaps: listUsers.filter(it => it.status === '2'),
          nothere: listUsers.filter(it => it.status === '3')
        });
      }

      let tmpDay = 1;
      while (listTmpOfDay.length) {
        if (tmpDay === listTmpOfDay[0].date.getDay()) {
          this.listOfDays.push(listTmpOfDay[0]);
          listTmpOfDay.shift();
          tmpDay++;
        } else if (tmpDay === 7) {
          tmpDay = 0;
        } else {
          tmpDay++;
          this.listOfDays.push(null);
        }
      }

    });
  }

  protected displayListUser(list): string {
    list = list.map(u => u.username);
    let response = '';
    list.forEach((element, index) => {
      response += element;
      if (index !== list.length - 1) {
        response += ' ; ';
      }
    });
    return response;
  }

  // protected checkDateExist(day: number, index: number, week: number): boolean {

  //   const tmpDate = new Date(this.dateToday.getFullYear(), this.dateToday.getMonth(), day + (week * 7));
  //   index = index + (week * 7);
  //   return typeof(this.listOfDays.find(d => d.date.getTime() === tmpDate.getTime())) !== 'undefined';
  // }

  protected clickLogin(): void {
    this.apiService.loginToDiscord();
  }

  protected addAvailability(day: Day, status: number): void {
    if (this.checkAuth()) {
      this.apiService.addAvailability(day.date.getTime(), this.currentUser.id, status, this.currentUser.username).subscribe((res) => {
        this.refresh();
      });
    } else {
      // todo error must login
    }
  }

  protected removeAvailability(day: Day): void {
    if (this.checkAuth()) {
      this.apiService.removeAvailability(day.date.getTime(), this.currentUser.id).subscribe((res) => {
        this.refresh();
      });
    }
  }

  protected nextMonth(): void {
    if (new Date().getMonth() === this.dateToday.getMonth() + 1) {
      this.dateToday = new Date();
    } else {
      this.dateToday = new Date(this.dateToday.getFullYear(), this.dateToday.getMonth() + 1, 1);
    }
    this.refresh();
  }

  protected previousMonth(): void {
    if (new Date().getMonth() === this.dateToday.getMonth() - 1) {
      this.dateToday = new Date();
    } else {
      this.dateToday = new Date(this.dateToday.getFullYear(), this.dateToday.getMonth() - 1, 1);
    }
    this.refresh();
  }

  private checkAuth(): boolean {
    const token = localStorage.getItem('token');
    const isAuth = (this.currentUser && token) ? true : false;
    if (!isAuth) {
      clearTimeout(this.timeout);
      this.showErrorMessage = true;
      this.timeout = setTimeout(() => {
        this.showErrorMessage = false;
      }, 2000);
    }
    return isAuth;
  }

  protected clearSession() {
    localStorage.removeItem('token');
    this.currentUser = null;
    this.avatar = null;
    this.router.navigate(['/']);
    this.loading = false;
  }
}
