import { Component, Input, EventEmitter, Output} from '@angular/core';
import Day from '../model/day.model';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html'
})
export class UserListComponent {

  @Input() userList: any[] = null;
  @Input() currentUser = null;

  @Output() removeAvailability = new EventEmitter<any>();

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) {
  }


  protected displayListUser(): string {
    const list = this.userList.map(u => u.username);
    let response = '';
    list.forEach((element, index) => {
      response += element;
      if (index !== list.length - 1) {
        response += ' ; ';
      }
    });
    return response;
  }

  protected removeAvailabilityChild(day: Day, userid: string) {
    this.removeAvailability.emit();
  }
}
